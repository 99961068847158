import React from "react";
import Layout from "components/layout";
import SEO from "components/layout/seo";
import YoutubeVideo from "components/pages/content-page/YoutubeVideo";
import ContentLinks from "components/pages/content-page/ContentLinks";
import ogImage from "assets/images/seo/index-main.jpg";
import ogImageVK from "assets/images/seo/vk/index-main.jpg";
import Mailing from "components/common/mailing";

const ContentPage = ({ path, pageContext }) => {
  const isContentLinksExists =
    pageContext.data.contentpdf ||
    pageContext.data.contentlinkmedium ||
    pageContext.data.contentlinkbehance ||
    pageContext.data.contentlinkpinterest ||
    pageContext.data.contentaifilelink ||
    pageContext.data.contentziplink;

  return (
    <Layout>
      <SEO
        title={`${pageContext.data.contenttitle.text}`}
        url={`https://granich.design${path}`}
        ogImage={ogImage}
        ogImageVk={ogImageVK}
      />
      <div className="section-top-block" />
      <YoutubeVideo data={pageContext.data} />
      {isContentLinksExists && <ContentLinks data={pageContext.data} />}
      <Mailing />
    </Layout>
  );
};

export default ContentPage;
